import { initialState } from './index.js';
import Vue from 'vue';

export default {
    TOGGLE_COLLAPSE: (state, { status, step }) => {
        state.steps[step].collapsed = status;
    },
    ENABLE_STEP: (state, stepNumber) => {
        state.steps[stepNumber].collapsed = true;
        state.steps[stepNumber].disabled = false;
    },
    SET_FEED_DETAILS: (state, data) => {
        state.feedDetails = data;
        state.feedAutotime = data.autotime ? 1 : 0;
    },
    SET_FEED_ASSOC: (state, data) => {
        Vue.set(state, 'assocFieldsStated', data);
    },
    SET_FEED_ID: (state, id) => {
        state.feedId = id;
    },
    UPDATE_FORM_FIELD: (state, data) => {
        state.feedDetails[data.field] = data.value;

        if (data.field == 'autotime') {
            state.feedAutotime = data.value ? 1 : 0;
        }
        if (data.field == 'assoc_fields') {
            state.assocFieldsStated = data.value;
        }
    },
    UPDATE_FORM_FIELDS: (state, data) => {
        for (const n in data) {
            // state.feedDetails[n] = data[n];
            Vue.set(state.feedDetails, n, data[n]);
        }

        if (data && typeof data['autotime'] != 'undefined') {
            state.feedAutotime = data.autotime ? 1 : 0;
        }
        if (data && typeof data['assoc_fields'] != 'undefined') {
            state.assocFieldsStated = data.assoc_fields;
        }
    },
    SET_AS_EDIT: (state) => {
        state.isEdit = true;
    },
    SET_FEED_VALIDATED: (state, status) => {
        state.feedValidated = status;
    },
    SET_FEED_RAW_FIELDS: (state, data) => {
        state.feedRawFields = data;
    },
    SET_FEED_AUTOMAP_FIELDS: (state, data) => {
        state.feedAutoMapFields = data;
    },
    SET_FEED_AUTODOWNLOAD_COMPAT: (state, data) => {
        state.autodownload = data;
    },
    SET_FEED_USEAUTODOWNLOAD_COMPAT: (state, data) => {
        state.feedAutotime = data;
    },
    SET_PRODUCT_CONTAINER: (state, data) => {
        state.assocProductContainer = data;
    },
    SET_CURRENT_ASSOC: (state, data) => {
        state.currentAssoc = data;
    },
    SET_FIELD_SELECTED: (state, data) => {
        state.fieldAssocSelected = data;
    },
    SET_PARSED_FIELDS: (state, data) => {
        state.parsedFields = data;
    },
    SET_LOADING: (state, { step, status }) => {
        state.loading[step] = status;
    },
    DISABLE_STEP: (state, stepNumber) => {
        state.steps[stepNumber].collapsed = false;
        state.steps[stepNumber].disabled = true;
    },
    SET_RAW_CATEG_ASSOC: (state, data) => {
        state.rawCategoryAssoc = data;
    },
    CLOSE_STEPTS_EXCEPT: (state, step) => {
        for (const s in state.steps) {
            if (s !== step) state.steps[s].collapsed = false;
        }
    },
    SET_CATEGORY_ASSOC: (state, { field, categ }) => {
        state.categoryAssoc[field] = categ;
    },
    REMOVE_CATEGORY_ASSOC: (state, field) => {
        delete state.categoryAssoc[field];
    },
    SET_STEP_FINISHED: (state, step) => {
        state.steps[step].finished = true;
    },
    RESET_STATE: (state) => {
        const iState = initialState();
        for (const prop in state) {
            if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
                state[prop] = iState[prop];
            }
        }
    },
    SET_FEED_LIST: (state, data) => {
        state.feeds = data;
    },
    SET_IMPORT_TYPE: (state, status) => {
        state.feedDetails.import_type = status;
    },
    SET_FEED_TYPE: (state, status) => {
        state.feedDetails.feed_type = status;
    },
    SET_AVAILABLE_FIELDS: (state, fields) => {
        state.allFields = fields;
    }
};
