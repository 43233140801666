import {
    getFeedDetails,
    validateFeedGeneralSettings,
    validateFeedLink,
    parseFeedFile,
    saveAssocFields,
    getColumnAssoc,
    saveCategoryAssoc,
    saveRunTimePrefs,
    changeFeedStatus,
    resetFieldAssoc,
    runFeed,
    getFeedHistory,
    viewFeedHistoryDetails,
    getFeeds
} from '@/api/publicare';

export default {
    GetFeedHistory({ commit }, data) {
        return new Promise((resolve, reject) => {
            getFeedHistory(data).then((res) => {
                resolve(res);
            });
        }).catch((err) => {
            reject(err);
        });
    },
    GetFeeds({ commit }, filters) {
        return new Promise((resolve, reject) => {
            getFeeds(filters).then((res) => {
                if (typeof res.message !== 'undefined') {
                    commit('SET_FEED_LIST', res.message);
                    resolve();
                } else {
                    reject(new Error('Eroare la preluarea feedurilor'));
                }
            });
        }).catch((err) => {
            reject(err);
        });
    },
    ChangeFeedStatus({ commit }, { id, status }) {
        return new Promise((resolve, reject) => {
            changeFeedStatus({
                id,
                status
            }).then((res) => {
                if (typeof res.message !== 'undefined') {
                    resolve(res);
                } else {
                    reject(Error('Eroare la schimbarea statusului'));
                }
            });
        }).catch((err) => {
            reject(err);
        });
    },
    ValidateLink({ commit }, url) {
        return new Promise((resolve, reject) => {
            validateFeedLink({
                feed_url: url
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    FetchFeedDetails({ commit }, id) {
        id = parseInt(id);
        return new Promise((resolve, reject) => {
            getFeedDetails({
                id
            })
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('SET_FEED_DETAILS', res.message);
                        commit('SET_FEED_ID', res.message.id);

                        if (typeof res.message.assoc_fields != 'undefined') {
                            commit('SET_FEED_ASSOC', res.message.assoc_fields);
                        }

                        resolve(res);
                    }
                })
                .catch((err, data) => {
                    reject(Error(0));
                });
        });
    },
    ValidateDetails({ commit, getters }, data) {
        if (getters.feedId !== '') {
            data.id = getters.feedId;
        }
        commit('SET_FEED_VALIDATED', false);
        return new Promise((resolve, reject) => {
            validateFeedGeneralSettings(data)
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    ParseFeedFile({ commit, state }) {
        return new Promise((resolve, reject) => {
            parseFeedFile({
                feed_url: state.feedDetails.feed_url,
                feed_type: state.feedDetails.feed_type,
                import_type: state.feedDetails.import_type,
                field_separator: state.feedDetails.field_separator
            })
                .then((res) => {
                    if (typeof res.message === 'object') {
                        if (typeof res.message.autodownload == 'boolean') {
                            commit('SET_FEED_AUTODOWNLOAD_COMPAT', res.message.autodownload);
                        }
                        if (typeof res.message.feed_type !== 'undefined') {
                            commit('SET_FEED_TYPE', parseInt(res.message.feed_type));
                        }
                        if (typeof res.message.automap !== 'undefined' && res.message.automap.length > 0) {
                            commit('SET_FEED_AUTOMAP_FIELDS', res.message.automap);
                        }
                        if (typeof res.message.data === 'object' && Object.keys(res.message.data).length > 0) {
                            commit('SET_FEED_RAW_FIELDS', res.message.data);
                        }
                        if (typeof res.message.allFields !== 'undefined' && res.message.allFields.length > 0) {
                            commit('SET_AVAILABLE_FIELDS', res.message.allFields);
                        }
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    SaveAssocFields({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveAssocFields(data)
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    GetColumnAssoc({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            getColumnAssoc({
                id
            })
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    SaveCategoryAssoc({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveCategoryAssoc(data)
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    SaveRunTimePrefs({ commit }, { id, data, useAutoDownload }) {
        return new Promise((resolve, reject) => {
            saveRunTimePrefs({
                id,
                data,
                useAutoDownload
            })
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    ResetFieldAssoc({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            resetFieldAssoc({
                id
            })
                .then((res) => {
                    if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
                        commit('UPDATE_FORM_FIELDS', res.message);
                    } else {
                        reject(err);
                        return false;
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    RunFeed({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            runFeed({
                id
            }).then((res) => {
                resolve(res);
            });
        }).catch((err) => {
            reject(err);
        });
    },
    ViewFeedHistoryDetails({ commit }, data) {
        return new Promise((resolve, reject) => {
            viewFeedHistoryDetails(data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};
