<template>

	<div class="app-container" v-if="ready">
		<el-card class="box-card" :shadow="'hover'">
			<router-link :to="{path: '/publicare/feeduri'}">
				<el-button type="text" size="medium" icon="el-icon-arrow-left">Inapoi la feeduri</el-button>
			</router-link>
		</el-card>
		<Box :type="'warning'" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li><strong>Nu uitati sa salvati modificarile inainte de a trece la urmatorul pas.</strong></li>
				</ul>
			</div>
		</Box>
		<div class="separator"></div>
		<step-one></step-one>
		<div class="separator"></div>
		<step-two></step-two>
		<div class="separator"></div>
		<step-three></step-three>
		<div class="separator"></div>
		<step-four></step-four>
	</div>
	<dd-card class="app-container" v-loading="true" v-else>
	</dd-card>

</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	const StepOne = () => import('./components/feed.step.1');
	const StepTwo = () => import('./components/feed.step.2');
	const StepThree = () => import('./components/feed.step.3');
	const StepFour = () => import('./components/feed.step.4');
	import {
		EventBus
	} from '@/utils/Bus.js';

	import {
		createFeedsStore
	} from '@/store/modules/feeds/index.js';

	// import {
	// 	debugPromise
	// } from '@/utils/index'

	export default {
		components: {
			Box,
			'dd-card': DDCard,
			'step-one': StepOne,
			'step-two': StepTwo,
			'step-three': StepThree,
			'step-four': StepFour
		},
		props: ['id'],
		data() {
			return {
				ready: false,
				mode: 'ADD', // ADD - add feed, EDIT - edit feed
			};
		},
		methods: {},
		computed: {
			isEdit() {
				return typeof this.id !== 'undefined' && parseInt(this.id) !== 0;
			}
		},
		beforeCreate() {
			this.$store.registerModule('feeds', createFeedsStore());
			this.$store.commit('feeds/RESET_FEEDS_STORE');
		},
		created() {
			if (this.isEdit) this.$store.commit('feeds/SET_AS_EDIT');
			if (typeof this.id !== 'undefined' && parseInt(this.id) !== 0) {

				this.$store.dispatch('feeds/FetchFeedDetails', this.id).then((res) => {
					this.ready = true;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'FetchFeedDetails'
						}
					});
					this.$router.push('/publicare/feeduri').catch(() => {});
				});
			} else {
				this.ready = true;
			}
			EventBus.$on('feedValidated', (id) => {
				if (!this.isEdit) {
					this.$router.push('/publicare/feed/' + id).catch(() => {});
					window.location.reload();
				}
			});
		},
		beforeDestroy() {
			EventBus.$off();
			this.$store.unregisterModule('feeds');
		}
	};
</script>