import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = () => ({
	feeds: [],
	feedRawFields: {},
	feedAutoMapFields: {},
	feedValidated: false,
	autodownload: false,
	feedDetails: {},
	assocFieldsStated: {},
	feedAutotime: false,
	feedId: '',
	isEdit: false,
	steps: {
		one: {
			collapsed: true,
			disabled: false,
			finished: false,
			number: 1
		},
		two: {
			collapsed: false,
			disabled: true,
			finished: false,
			number: 2
		},
		three: {
			collapsed: false,
			disabled: true,
			finished: false,
			number: 3
		},
		four: {
			collapsed: false,
			disabled: true,
			finished: false,
			number: 4
		}
	},
	loading: {
		1: false,
		2: false,
		3: false,
		4: false
	},
	runTimePrefs: {
		days: [],
		hours: []
	},
	rawCategoryAssoc: [],
	categoryAssoc: {},
	currentAssoc: 0,
	fieldAssocSelected: '',
	assocProductContainer: '',
	parsedFields: {},
	allFields: []
});

export function createFeedsStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}