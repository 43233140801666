export default {
	feedDetails: (state) => state.feedDetails,
	assocFields: (state) => state.assocFieldsStated,
	autodownload: (state) => state.autodownload,
	feedId: (state) => state.feedId,
	feedValidated: (state) => state.feedValidated,

	hasParsedFields: (state) => {
		let statusHasParsedFields = typeof state.parsedFields !== 'undefined' && Object.keys(state.parsedFields).length > 0;
		return statusHasParsedFields;
	},

	feedStep: (state) => typeof state.feedDetails.feed_status !== 'undefined' && state.feedDetails.feed_status != null ? parseInt(state.feedDetails.feed_status) : 0,
	latestCompletedStep: (state) => {
		let latest = state.steps.one;
		for (const n in state.steps) {
			if (state.steps[n].finished && state.steps[n].number > latest.number) {
				latest = state.steps[n];
			}
		}
		return latest.number;
	},
	feedStepts: (state) => state.steps,
	addProductMode: (state) => typeof state.feedDetails.import_type !== 'undefined' && parseInt(state.feedDetails.import_type) === 1,
	feedsList: (state) => typeof state.feeds.feeds !== 'undefined' ? state.feeds.feeds : [],
	isLoading: (state) => {
		let isLoading = false;
		for (const step in state.loading) {
			if (state.loading[step]) isLoading = true;
		}
		return isLoading;
	},
	isXMLAssoc: (state) => {
		return parseInt(state.feedDetails.feed_type) === 2;
	},
};